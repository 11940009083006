<template>
  <div>
    <vx-card>
      <div class="flex flex-wrap justify-between items-center mb-1">
        <div class="mb-4 md:mb-0 mr-4">
          <div class="flex space-x-2">
            <vs-button v-if="selectable && multiSelect" color="primary" type="border" icon-pack="feather" icon="icon-check" class="px-3" :disabled="table.rowsChecked.length < 1" @click="emitCheckedItems">
              Pilih {{ table.rowsChecked.length }} Item
            </vs-button>
          </div>
        </div>

        <div class="flex flex-wrap items-center sm:justify-between w-full sm:w-auto">
          <!--limit chooser-->
          <PagingLimit :currentPage="table.page" :limit="table.limit" :total="table.totalItems" @changeLimit="onPageLimitChange"/>

          <div class="w-full sm:w-auto flex">
            <!--search-->
            <vs-input icon-pack="feather" icon="icon-search" class="mb-4 md:mb-0 mr-4 w-full" placeholder="Cari" v-model="table.search" @keyup="onSearch"/>

            <!--actions-->
            <vs-dropdown vs-trigger-click class="dd-actions cursor-pointer">
              <vs-button class="mb-4 md:mb-0 px-3 py-3 flex items-center justify-center" type="filled" icon-pack="feather" icon="icon-more-vertical"/>
              <vs-dropdown-menu>
                <vs-dropdown-item>
                  <ExportCsv v-if="$refs.mainTable" :data="$refs.mainTable._props.data" />
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </div>
        </div>
      </div>

      <!--loading-->
      <vs-progress indeterminate color="primary" :height="1" :class="{ 'invisible': !table.loading }"/>

      <!--table-->
      <vs-table
        ref="mainTable"
        :class="{ 'animate-pulse': table.loading }"
        :sst="true"
        :data="table.rows"
        @sort="onSort"
        stripe>
        <template slot="thead">
          <vs-th class="whitespace-no-wrap" v-if="selectable && multiSelect"></vs-th>
          <vs-th class="whitespace-no-wrap">Tipe</vs-th>
          <vs-th class="whitespace-no-wrap">Platform</vs-th>
          <vs-th class="whitespace-no-wrap">Description</vs-th>
        </template>
        <template slot-scope="{data}">
          <vs-tr-custom class="text-sm"
                        v-for="(item, index) in data" :key="index"
                        :data="selectable ? item : null"
                        :class="{'cursor-pointer': selectable, 'cursor-not-allowed opacity-50': isItemDisabled(item), 'text-primary': isItemChecked(item)}"
                        @click="onSelected(item)">
            <vs-td class="whitespace-no-wrap" v-if="selectable && multiSelect">
              <vs-checkbox class="p-0" :checked="isItemChecked(item)" size="small"/>
            </vs-td>
            <vs-td>{{ item.tipe }}</vs-td>
            <vs-td>{{ item.platform }}</vs-td>
            <vs-td>{{ item.description }}</vs-td>
          </vs-tr-custom>
        </template>
      </vs-table>

      <!--paging-->
      <div>
        <vs-pagination class="text-sm mt-6 overflow-x-scroll hidden sm:block" v-model="table.page" @change="onPageChange" :total="totalPages"/>
        <div class="flex w-full justify-end mt-6 justify-around sm:hidden">
          <vs-button radius color="primary" type="border" icon-pack="feather" icon="icon-chevron-left" @click="table.page > 1 && table.page--; onPageChange"/>
          <vs-button radius color="primary" type="border" icon-pack="feather" icon="icon-chevron-right" @click="table.page < totalPages && table.page++; onPageChange"/>
        </div>
      </div>
    </vx-card>
  </div>
</template>

<script>
import MediaPlatformRepository from '@/repositories/master/media-platform-repository'
import PagingLimit from '@/views/components/paging-limit/PagingLimit'
import VsTrCustom from '@/views/components/vs-table-custom/VsTrCustom'
import _ from 'lodash'

export default {
  name: 'MediaPlatform',
  components: {
    ExportCsv: () => import('@/views/components/export-csv/ExportCsv'),
    VsTrCustom,
    PagingLimit
  },
  props: {
    selectable: { default: false, type: Boolean },
    multiSelect: { default: false, type: Boolean },
    externalFilter: { default: null, type: Object },
    disableIds: { default: null, type: Array }
  },
  watch: {
    externalFilter (newVal, oldVal) {
      if (newVal && !_.isEmpty(newVal) && !_.isEqual(newVal, oldVal)) {
        this.getData()
      }
    }
  },
  data () {
    return {
      modalAdd: {
        active: false
      },
      modalEdit: {
        active: false,
        item: {}
      },
      table: {
        loading: false,
        page: 1,
        limit: 10,
        totalItems: 0,
        sort: '',
        search: '',
        rows: [],
        rowsChecked: []
      }
    }
  },
  computed: {
    totalPages () {
      return Math.ceil(this.table.totalItems / this.table.limit)
    }
  },
  methods: {
    getData () {
      this.table.loading = true
      const params = (({ page, limit, search, sort }) => ({ page, limit, search, sort }))(this.table)

      if (this.externalFilter) {
        params.externalFilter = JSON.stringify(this.externalFilter)
      }

      MediaPlatformRepository.get(params)
        .then(response => {
          this.table.rows = response.data.data.data
          this.table.totalItems = response.data.data.total
          if (this.table.page > response.data.data.last_page) {
            this.table.page = response.data.data.last_page
          }
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
        .finally(() => {
          this.table.loading = false
        })
    },

    delete (id) {
      MediaPlatformRepository.delete(id)
        .then(response => {
          this.getData()
          this.notifySuccess('Data berhasil terhapus')
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
    },

    confirmDelete (id) {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Konfirmasi',
        text: 'Yakin ingin menghapus data ini?',
        acceptText: 'Hapus',
        cancelText: 'Batal',
        accept: () => { this.delete(id) }
      })
    },

    showModalEdit (item) {
      this.modalEdit.item = item
      this.modalEdit.active = true
    },

    isItemChecked (item) {
      return _.findIndex(this.table.rowsChecked, it => it.id === item.id) !== -1
    },

    isItemDisabled (item) {
      const disabledIds = this.disableIds || []
      return _.includes(disabledIds, item.id)
    },

    onPageChange () {
      this.getData()
    },

    onPageLimitChange (limit) {
      this.table.limit = limit
      if (this.table.page > this.totalPages) {
        this.table.page = this.totalPages
      }
      this.getData()
    },

    onSelected (item) {
      if (this.selectable && !this.isItemDisabled(item)) {
        if (this.multiSelect) {
          const index = _.findIndex(this.table.rowsChecked, it => it.id === item.id)
          if (index === -1) {
            this.table.rowsChecked.push(item)
          } else {
            this.table.rowsChecked.splice(index, 1)
          }
        } else {
          this.$emit('selected', _.cloneDeep(item))
        }
      }
    },

    emitCheckedItems () {
      this.$emit('selected', _.cloneDeep(this.table.rowsChecked))
      this.table.rowsChecked = []
    },

    onSort (key, order) {
      this.table.sort = (key === null || order === null) ? '' : `${key} ${order}`
      this.getData()
    },

    onSearch: _.debounce(function (e) {
      this.getData()
    }, 500)
  }
}
</script>
